import React from 'react'

import * as S from './style'

const Footer = () => {
  return (
    <S.Footer>
      <p>All right reserved @ Copyright 2022</p>
    </S.Footer>
  )
}

export default Footer